<template>
  <div class="container py-5">
    <p class="fw-bold">Workshop at AMWC 2022</p>
    <p>
        時間：2022 05/07-08<br>
        官方網站：<a href="https://www.euromedicom.com/amwc-asia/en/about/about-amwc-asia-2022.html">https://www.euromedicom.com/amwc-asia/en/about/about-amwc-asia-2022.html</a><br>
        線上展覽：<a href="https://web.micepad.co/AMWCAsia-TDAC2022/info">https://web.micepad.co/AMWCAsia-TDAC2022/info</a><br><br> 

        <b>Workshop</b><br>
        時間：05/07 12:30-13:30<br>
        地點：North Lounge<br><br>

        <b>Moderator：彭于賓/ Yu-Pin Peng, MD</b><br>

        <img
          class="img-md rounded"
          src="@/assets/images/zh-TW/news/new_05_workshop_1.jpg"
        /><br><br><br> 
        
        <b>Speaker：張長正 / Chang-Cheng  Chang, MD Ph.D</b><br>
        Topic：AI assisted skin image system (Observ) for quantitative facial aging analysis<br>

        <img
          class="img-md rounded"
          src="@/assets/images/zh-TW/news/new_05_workshop_2.jpg"
        /><br><br><br> 

        <b>Speaker：李至偉 / Chili Chih-Wei Li, MD</b><br> 
        Topic：AI Technologies Being Developed for Esthetic Practices<br> 

        <img
          class="img-md rounded"
          src="@/assets/images/zh-TW/news/new_05_workshop_3.jpg"
        /><br><br><br> 
        
        <b>Speaker：黃昭瑜 / Charlene CY Ng, MD</b><br>
        Topic：Black or White ? Evaluation of facial dyschromia with advance skin imaging analysis.<br>

        <img
          class="img-md rounded"
          src="@/assets/images/zh-TW/news/new_05_workshop_4.jpg"
        /><br><br>
    </p>

  </div>
</template>
<script>
export default {};
</script>
